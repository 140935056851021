import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { FAQ } from '../model/faq';


@Injectable({
  providedIn: 'root'
})
export class FAQService {
  getCategoryNameById(id: number) {
    throw new Error('Method not implemented.');
  }
  private apiPath: string;
  constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


  async addfaq(FAQ: FAQ) {
    FAQ.faqId = 0;
    let res = await this.service.Data.ExecuteAPI_Post("Faq", FAQ);
    console.log(res);
    return res;
  }


  async getfaq(id: number): Promise<any> {
    console.log(id,"ideditapinumber")
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("Faq/{id}?faqid=" + id);
    } catch (error) {
      console.error('Error fetching categories:', error);
      return null;
    }
  }

  async getAllfaq() {
    //return this.http.get<any>(this.apiPath + '/api/Gallery');
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("Faq");
    } catch (error) {
      console.error('Error fetching ImageGallerylist:', error);
      return [];
    }
  }

  async updatefaq(FAQ: any) {
    console.log(FAQ,"FAQinapicall");
    let res = await this.service.Data.ExecuteAPI_Put("Faq", `${FAQ.faqId }?faqid=${FAQ.faqId }`, FAQ);
    console.log(res,"resafterimageupdate");
    return res;
  }

  async deletefaq(id: number) {
    console.log(id,"iddeletebefore")
    let res = await this.service.Data.ExecuteAPI_Delete("Faq", `{id}?faqid=${id}`);
    console.log(res,"resafetrdelete")
    return res;
  }
}
