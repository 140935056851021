
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyLogo } from '../../model/logo';
import { AlertifyService } from '../../services/alertify.service';
import { LogoService } from '../../services/logo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Account_Model } from '../../model/common_model';
import { SystemService } from '../../services/system.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-logo-form',
    templateUrl: './logo.html',
    styleUrls: ['./logo.css'],
})
export class logoFormComponent implements OnInit {
    LogoForm: FormGroup;
    Logos: any;
    selectedProfile: CompanyLogo | null = null;
    fileName: any;
    public imageUrl: string = "../../../../assets/profile.png";
    public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    Account: Account_Model;
    UserId: string;
    type: string;
    companyId: number;
    logoData: any;
    apiPath: any;

    constructor(private fb: FormBuilder, public service: SystemService, private router: Router, private alertify: AlertifyService, private LogoService: LogoService, private route: ActivatedRoute) {
        this.loadForm();
        this.service.HasAccountData.then(() => {
            this.Account = this.service.getUserAccountData();
            console.log(JSON.stringify(this.Account));
            console.log(this.Account.userId);
        });
        console.log(JSON.stringify(this.service.App.getCookie("Bearer")))
        if (!this.service.App.getCookie("Bearer")) {
            console.log(this.service.App.getCookie("Bearer"));
            localStorage.clear();
            this.service.resetPromise();
            this.service.App.setCookie("Bearer", "", 0, "");
            localStorage.removeItem('isAdmin');
            this.Account = <Account_Model>{ UserID: 0, UserName: "" };
            console.log("redirectToLogin");
            this.service.redirectToLogin();
        }
        this.apiPath = this.service.Settings.Base_API_URL;

    }

    async ngOnInit() {
        this.loadlogo();
        const Logo: CompanyLogo = this.LogoForm.value;
        this.UserId = this.service.Account.userId;
        try {
            if (this.UserId) {
                console.log(Logo.companyId, "Logo.companyId");
                let logoData = await this.LogoService.getProfileByUserId(Logo.companyId);
                console.log(JSON.stringify(logoData));
                this.Logos = logoData;
                logoData = this.convertKeysToCamelCase(logoData);
                console.log(JSON.stringify(logoData));
                this.LogoForm.patchValue(logoData);
                this.imageUrl = logoData.logoPath;
                this.type = "Update";
                this.loadProfiles(this.UserId);
            }
            else {
                this.type = "Save";
                this.alertify.error("Please update the profile.");
            }
        }
        catch {
            this.type = "Save";
            // this.alertify.error("Please update the profile.");

        }
        this.UserId = this.service.Account.userId;
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    loadForm() {
        this.LogoForm = this.fb.group({
            companyId: 0,
            companyName: ['', Validators.required],
            phoneNo: ['', Validators.required],
            companyTitle: ['', Validators.required],
            address: ['', Validators.required],
            logoPath: ['', Validators.required],
            description: ['', Validators.required],
            email: ['', Validators.required],
            webiste: ['', Validators.required],
            createdDate: [''],
            active: 0,

        });

    }

    ProfilePictureName: string = "";
    fileChange(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    this.ProfilePictureName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {

            }
        }
    }

    Back() {
        this.router.navigate(['/adminPanel']);
    }
    loadProfiles(UserId: any) {
        console.log(this.UserId);
    }

    loadlogoUserId(UserId: any) {
        let res = this.LogoService.getProfileByUserId(UserId);
        console.log(JSON.stringify(res));
        return res;
    }
    loadlogo() {
        this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
            this.logoData = logoData;
            console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
            this.imageUrl = this.apiPath + "/Documents/appImages/" + logoData.logoPath;
            this.LogoForm.patchValue({ companyName: logoData.companyName });
            this.LogoForm.patchValue({ companyTitle: logoData.companyTitle });
            this.LogoForm.patchValue({ description: logoData.description });
            this.LogoForm.patchValue({ email: logoData.email });
            this.LogoForm.patchValue({ phoneNo: logoData.phoneNo });
            this.LogoForm.patchValue({ address: logoData.address });
            this.LogoForm.patchValue({ webiste: logoData.webiste });
            this.type = "Update";
            this.companyId=logoData.companyId;
            // this.loadProfiles(res); // Refresh the Profile list
            //this.router.navigate(['/']);
        });
    }

    async onSubmit() {
        const Logo: CompanyLogo = this.LogoForm.value;
        // let Logo = this.LogoForm.getRawValue();
        console.log(this.companyId, "Logo.companyIdonsubmit")
        if (this.companyId) {
            let res = this.LogoService.updateProfile(Logo);
            if (res) {
                console.log(res, "resafterupdate");
                this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
                    this.logoData = logoData;
                    console.log( this.logoData , " this.logoData update");
                    this.imageUrl = this.apiPath + "/Documents/appImages/" + logoData.logoPath;
                    this.LogoForm.patchValue({ companyName: logoData.companyName });
                    this.LogoForm.patchValue({ companyTitle: logoData.companyTitle });
                    this.LogoForm.patchValue({ description: logoData.description });
                    this.LogoForm.patchValue({ email: logoData.email });
                    this.LogoForm.patchValue({ phoneNo: logoData.phoneNo });
                    this.LogoForm.patchValue({ address: logoData.address });
                    this.LogoForm.patchValue({ webiste: logoData.webiste });
                });
            }

        } else {
            // Add new Profile
            // Update existing Profile
            Logo.logoPath = this.imageUrl;
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            Logo.createdDate = formattedDate;
            Logo.active = 0;
            let res = this.LogoService.addProfile(Logo);
            console.log(JSON.stringify(res));
            if (res) {
                console.log(res, "resafteradd");
                this.LogoService.GetLatestCompanyDetailslatest().then(logoData => {
                    this.logoData = logoData;
                    console.log(this.logoData, "logoDataGetLatestCompanyDetailslatest");
                    this.imageUrl = this.apiPath + "/Documents/appImages/" + logoData.logoPath;
                    this.LogoForm.patchValue({ companyName: logoData.companyName });
                    this.LogoForm.patchValue({ companyTitle: logoData.companyTitle });
                    this.LogoForm.patchValue({ description: logoData.description });
                    this.LogoForm.patchValue({ email: logoData.email });
                    this.LogoForm.patchValue({ phoneNo: logoData.phoneNo });
                    this.LogoForm.patchValue({ address: logoData.address });
                    this.LogoForm.patchValue({ webiste: logoData.webiste });
                    this.type = "Update";
                    this.loadProfiles(res); // Refresh the Profile list
                    //this.router.navigate(['/']);
                });


            }
        }
        // Reset the form
        this.LogoForm.reset();
        this.selectedProfile = null;
    }

    onEdit(Logo: CompanyLogo) {
        // Load the selected Profile into the form for editing
        this.selectedProfile = Logo;
        this.LogoForm.patchValue(Logo);
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }
}


export { CompanyLogo };

