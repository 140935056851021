import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { VideoGallery } from '../model/videogallery';


@Injectable({
  providedIn: 'root'
})
export class VideoGalleryService {
  getCategoryNameById(id: number) {
    throw new Error('Method not implemented.');
  }
  private apiPath: string;
  constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


  async addGallery(VideoGallery: VideoGallery) {
    VideoGallery.videoGalleryId = 0;
    let res = await this.service.Data.ExecuteAPI_Post("VideoGallery", VideoGallery);
    console.log(res);
    return res;
  }


  async getGallery(id: number): Promise<any> {
    console.log(id,"ideditapinumber")
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("VideoGallery/{id}?videogalleryid=" + id);
    } catch (error) {
      console.error('Error fetching categories:', error);
      return null;
    }
  }

  async getGalleryTypeDetails(id: number): Promise<any> {
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("Gallery/typeDetails/{id}?typeId=" + id);
    } catch (error) {
      console.error('Error fetching categories:', error);
      return null;
    }
  }

  async getAllGallerys() {
    //return this.http.get<any>(this.apiPath + '/api/Gallery');
    try {
      return await this.service.Data.ExecuteAPI_Get<any>("VideoGallery");
    } catch (error) {
      console.error('Error fetching VideoGallerylist:', error);
      return [];
    }
  }

  getUserGallerys(userName: string) {
    return this.http.get<VideoGallery[]>(this.apiPath + '/api/Gallery/userGallery/' + userName);
  }

  async updateGallery(VideoGallery: any) {
    console.log(VideoGallery,"VideoGalleryinapicall");
    let res = await this.service.Data.ExecuteAPI_Put("VideoGallery", `${VideoGallery.VideoGalleryId}?videoGalleryId=${VideoGallery.VideoGalleryId}`, VideoGallery);
    console.log(res);
    return res;
  }

  async deleteGallery(id: number) {
    let res = await this.service.Data.ExecuteAPI_Delete("VideoGallery", `{id}?videoGalleryId=${id}`);
    return res;
  }
}
