// Profile.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { CompanyLogo } from '../model/logo';


@Injectable({
    providedIn: 'root',
})
export class LogoService {
    apiUrl: any;
    BearerToken: any;
    Logo: CompanyLogo[] = [];
    httpOptions: { headers: any; };



    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    // async getAllProfile(): Promise<Logo[]> {
    //     try {
    //         return await this.service.Data.ExecuteAPI_Get<Logo[]>("Profile");
    //     } catch (error) {
    //         // Handle errors here
    //         console.error('Error fetching Profile:', error);
    //         return []; // or throw the error, depending on your error-handling strategy
    //     }
    // }

    // getProfileById(id: number): Observable<Profile> {
    //     return this.http.get<Profile>(`${this.apiUrl}/api/Profile/${id}`);
    // }

    async getProfileByUserId(companyId: number)
    {
        try {
            let res = await this.service.Data.ExecuteAPI_Get<CompanyLogo>("CompanyDetail/{id}?companyId=" + companyId);
            console.log(res,"resbyidapi");
            return res;
        } catch (error) {
            console.error('Error fetching Profile:', error);
            return null; // or throw the error, depending on your error-handling strategy
        }
    }
    async GetLatestCompanyDetailslatest(): Promise<CompanyLogo> {
        try {
          return await this.service.Data.ExecuteAPI_Get<CompanyLogo>("CompanyDetail/GetLatestCompanyDetailslatest");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Company Details:', error);
          return null;
        }
      }

    async addProfile(Logo: CompanyLogo): Promise<Observable<CompanyLogo>> {
        Logo.companyId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("CompanyDetail", Logo);
        return res;
    }
    async updateProfile(Logo: CompanyLogo): Promise<Observable<CompanyLogo>> {
        console.log(Logo,"Logobeforeapicall")
        let res = await this.service.Data.ExecuteAPI_Put("CompanyDetail", `${Logo.companyId}?companyId=${Logo.companyId}`, Logo);
        return res;
    }

}
